import { createApp } from 'vue';
import App from './App.vue';
import { TUIComponents, TUICore, genTestUserSig } from './TUIKit';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import router from './router/index';

const SDKAppID = 1600051403; // Your SDKAppID
const secretKey = 'e83e8197af5dcc86871cea5b6b63b55ad1587471c0e62177e1e426d7c9349011'; //Your secretKey

const userID = localStorage.getItem('account') ? localStorage.getItem('account') : 'sys001'
console.log(userID)
// init TUIKit
const TUIKit = TUICore.init({
    SDKAppID,
});
// TUIKit add TUIComponents
TUIKit.use(TUIComponents);

// login TUIKit

TUIKit.login({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
    userID: userID,
    userSig: genTestUserSig({
        SDKAppID,
        secretKey,
        userID,
    }).userSig, // The password with which the user logs in to IM. It is the ciphertext generated by encrypting information such as userID.For the detailed generation method, see Generating UserSig
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
createApp(App).use(TUIKit).use(router).mount('#app');