const routes = [
    {
        name: 'chat',
        path: '/',
        component: () => import('@/views/index/chat')
    },
    {
        name: 'login',
        path: '/login',
        component: () => import('@/views/login/index')
    }
];

export default routes
